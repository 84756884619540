import React from "react";
import social from "../src/assets/social.webp";
import socialone from "../src/assets/socila1.webp";

function Footer() {
  return (
    <div
      //   className="row"
      style={{ backgroundColor: "aliceblue", padding: "40px" }}
    >
      <div className="row" style={{ justifyContent: "center" }}>
        <div className="col-md-3">
          {/* <div className="d-flex"> */}
          {/* <img
              src="https://vijayahomeservices.b-cdn.net/vhs-lgo.png"
              alt="loading...."
              style={{ width: "50px", height: "50px" }}
            /> */}
          <div
            className=" poppins-semibold"
            style={{ color: "darkred", fontSize: "16px", fontWeight: "bold" }}
          >
            Vijay Home Services
          </div>
          <div className="poppins-regular pt-2">
            Send anything, anywhere, anytime
          </div>
          {/* </div> */}
        </div>

        <div className="col-md-2">
          <div className="poppins-black">Company</div>
          <div className="poppins-regular pt-2">About Us</div>
          <div className="poppins-regular pt-2">Careers Blog</div>
          <div className="poppins-regular pt-2"> Blog</div>
        </div>

        <div className="col-md-3">
          <div className="poppins-black ">Quick Links</div>
          <div className="poppins-regular  pt-2">Services</div>
          <div className="poppins-regular pt-2">Tools</div>
          <div className="poppins-regular pt-2">API Integrations</div>
          <div className="poppins-regular pt-2"> Courier</div>
          <div className="poppins-regular pt-2"> Packers & Movers</div>
          <div className="poppins-regular pt-2"> Two Wheelers</div>
          <div className="poppins-regular pt-2"> Trucks</div>
          <div className="poppins-regular pt-2"> Porter For Enterprise</div>
        </div>

        <div className="col-md-2">
          <div className="poppins-black ">Support</div>
          <div className="poppins-regular  pt-2">Contact Us</div>
          <div className="poppins-regular pt-2">Privacy Policy</div>
          <div className="poppins-regular pt-2">Terms of Service</div>
          <div className="poppins-regular pt-2"> Insurance FAQs</div>
          <div className="poppins-regular pt-2">
            Driver Partner Terms & Conditions
          </div>
          <div className="poppins-regular pt-2"> Zero Tolerance Policy</div>
        </div>

        <div className="col-md-2">
          <a href="https://play.google.com/store/apps/details?id=com.vhs1">
            <img
              src={social}
              alt="loading...."
              style={{ width: "150px", height: "50px", marginTop: "10px" }}
            />
          </a>

          <a href="https://play.google.com/store/apps/details?id=com.vhs1">
            <img
              src={socialone}
              alt="loading...."
              style={{ width: "150px", height: "50px", marginTop: "10px" }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}
export default Footer;
