import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import vicon from "../src/assets/v-icon.svg";
import vicon1 from "../src/assets/v-icon1.svg";
import vicon2 from "../src/assets/v-icon2.svg";
import homebanner from "../src/assets/homebanner.jpg";
import vnicon from "../src/assets/vnicon.svg";
import vnicon1 from "../src/assets/vnicon1.svg";
import vnicon2 from "../src/assets/vnicon2.svg";
import vnicon3 from "../src/assets/vnicon3.svg";
import Accordion from "react-bootstrap/Accordion";
import hbanner from "../src/assets/hbanner.jpg";
import moment from "moment";
import axios from "axios";
import { Modal } from "react-bootstrap";
import cityimg from "../src/assets/cityimg.jpg";
import indiaflg from "../src/assets/indiaflg.png";
import dubai from "../src/assets/dubai.png";
import london from "../src/assets/london.webp";
import Header1 from "../src/Header1";
import phomebanner from "../src/assets/phomebanner.jpg";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

function Home() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [openResetModal, setOpenResetModal] = useState(false);

  const [name, setname] = useState("");
  const [contact1, setcontact1] = useState("");
  const [email, setemail] = useState("");
  const [comment, setcomment] = useState("");
  const [enquirydate, setenquirydate] = useState(moment().format("MM-DD-YYYY"));
  const [city, setCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [activeCity, setActiveCity] = useState("");
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropLocation, setDropLocation] = useState("");
  const [mobilenumber, setMobilenumber] = useState("");
  const [shiftingdate, setshiftingdate] = useState("");
  const [customer, setcustomer] = useState("");
  const [selectcategory, setselectcategory] = useState("");
  const [selectAddress, setSelectAddress] = useState(null);
  const [selectAddress1, setSelectAddress1] = useState(null);
  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);
  const handleResetModal = () => {
    setOpenResetModal(true);
  };

  const addEnquiry = async (e) => {
    e.preventDefault();

    if (!customer || !mobilenumber || !selectcategory) {
      alert("Please enter all fields");
    } else {
      try {
        const config = {
          url: "/enquiry/addenquiry",
          method: "post",
          baseURL: "https://pm.vijayhomeservice.in/api",

          headers: { "content-type": "application/json" },
          data: {
            // date: enquirydate,
            customer: customer,
            contact1: mobilenumber,
            category: selectcategory,
            reference: "Packers Movers",
          },
        };

        const response = await axios(config);

        if (response.status === 200) {
          const data = response.data.data;
          alert("Enquiry added successfully:", data);
          setShow(false);
          window.location.reload("");
        }
      } catch (error) {
        console.error("Error adding enquiry:", error);
      }
    }
  };

  useEffect(() => {
    getCity();
  }, []);

  const getCity = async () => {
    try {
      let res = await axios.get(
        "https://api.vijayhomesuperadmin.in/api/master/getcity"
      );
      if (res.status === 200) {
        const sortedCities = res.data.mastercity.sort((a, b) =>
          a.city.localeCompare(b.city)
        );
        setCity(sortedCities);
      }
    } catch (er) {
      console.log(er, "err while fetching data");
    }
  };

  const handleChange = (city) => {
    setOpenResetModal(false);
    setActiveCity(city.city);
    setSelectedCity(city.city);
  };

  const autocompletePickupRef = useRef(null);
  const autocompleteDropRef = useRef(null);

  const addpackersenquiry = async (e) => {
    e.preventDefault();

    if (!pickupLocation || !dropLocation || !mobilenumber || !shiftingdate) {
      alert("Please enter all fields");
    } else {
      try {
        const config = {
          url: "/enquiry/addenquiry",
          method: "post",
          baseURL: "https://pm.vijayhomeservice.in/api",
          headers: { "content-type": "application/json" },
          data: {
            pickupLocation: selectAddress,
            dropLocation: selectAddress1,
            city: selectedCity,
            contact1: mobilenumber,
            reference: "Packers Movers",
            enquiryDate: shiftingdate,
          },
        };
        const response = await axios(config);

        if (response.status === 200) {
          const data = response.data.data;
          alert("Enquiry added successfully:", data);
          window.location.reload("");
        }
      } catch (error) {
        console.error("Error adding enquiry:", error);
      }
    }
  };

  return (
    <div className="">
      <Header />
      <Header1 />
      <img src={homebanner} alt="loading..." style={{ width: "100%" }} />
      <div className="container">
        <div className="row mt-3">
          <div
            onClick={handleResetModal}
            className="col-md-2"
            style={{
              backgroundColor: "aliceblue",
              padding: "20px",
              borderBottom: "1px solid grey",
              borderColor: "grey",
            }}
          >
            <div className="poppins-black" style={{ color: "darkred" }}>
              <span>
                <i className="fa-solid fa-location-dot mx-2"></i>
              </span>
              {selectedCity ? selectedCity : "Select City"}
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{
            backgroundColor: "aliceblue",
            padding: "20px",
            justifyContent: "center",
          }}
        >
          <div className="col-md-2" style={{ borderRight: "1px solid grey" }}>
            <div className="poppins-black">
              Pickup Location{" "}
              <span className="" style={{ color: "red" }}>
                {" "}
                *
              </span>
            </div>

            <Autocomplete
              onLoad={(autocomplete) => {
                autocompletePickupRef.current = autocomplete; // Use pickup ref
              }}
              onPlaceChanged={() => {
                const place = autocompletePickupRef.current.getPlace();
                if (!place || !place.geometry) {
                  alert("Please select a valid location.");
                  return;
                }

                const formattedAddress =
                  place.formatted_address || "Unknown address";

                setPickupLocation(formattedAddress);
                setSelectAddress(formattedAddress);
                console.log({
                  address: formattedAddress,
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng(),
                });
              }}
            >
              <input
                type="text"
                className="poppins-light col-md-12"
                placeholder="Sending from"
                value={pickupLocation}
                onChange={(e) => setPickupLocation(e.target.value)}
                style={{
                  color: "grey",
                  fontSize: "12px",
                  border: "none",
                  outline: "none",
                  width: "100%",
                  padding: "8px",
                  marginTop: "5px",
                }}
              />
            </Autocomplete>
          </div>

          <div className="col-md-2" style={{ borderRight: "1px solid grey" }}>
            <div className="poppins-black">
              Drop Location{" "}
              <span className="" style={{ color: "red" }}>
                {" "}
                *
              </span>
            </div>
            <Autocomplete
              onLoad={(autocomplete) => {
                autocompleteDropRef.current = autocomplete; // Use drop ref
              }}
              onPlaceChanged={() => {
                const place = autocompleteDropRef.current.getPlace();
                if (!place || !place.geometry) {
                  alert("Please select a valid location.");
                  return;
                }

                const formattedAddress =
                  place.formatted_address || "Unknown address";

                setDropLocation(formattedAddress);
                setSelectAddress1(formattedAddress);
                console.log({
                  address: formattedAddress,
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng(),
                });
              }}
            >
              <input
                type="text"
                className="poppins-light col-md-12"
                placeholder="Sending to"
                value={dropLocation}
                onChange={(e) => setDropLocation(e.target.value)}
                style={{
                  color: "grey",
                  fontSize: "12px",
                  border: "none",
                  outline: "none",
                  width: "100%",
                  padding: "8px",
                  marginTop: "5px",
                }}
              />
            </Autocomplete>
          </div>
          <div className="col-md-2" style={{ borderRight: "1px solid grey" }}>
            <div className="poppins-black">
              Phone Number
              <span className="" style={{ color: "red" }}>
                {" "}
                *
              </span>
            </div>
            <input
              type="text"
              className="poppins-light"
              placeholder="Enter Contact Details"
              value={mobilenumber}
              onChange={(e) => setMobilenumber(e.target.value)}
              style={{
                color: "grey",
                fontSize: "12px",
                border: "none",
                outline: "none",
                width: "100%",
                padding: "8px",
                marginTop: "5px",
              }}
            />
          </div>
          <div className="col-md-2">
            <div className="poppins-black">
              Shifting Date
              <span className="" style={{ color: "red" }}>
                {" "}
                *
              </span>
            </div>
            <input
              type="date"
              className="poppins-light"
              placeholder="Please Select Date"
              value={shiftingdate}
              onChange={(e) => setshiftingdate(e.target.value)}
              style={{
                color: "grey",
                fontSize: "12px",
                border: "none",
                outline: "none",
                width: "100%",
                padding: "8px",
                marginTop: "5px",
                marginBottom: "10px",
              }}
            />
          </div>
          <div
            className="col-md-4"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              onClick={addpackersenquiry}
              className="poppins-black text-center"
              style={{
                color: "white",
                backgroundColor: "darkred",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer",
                width: "100%",
              }}
            >
              Check Price
            </div>
          </div>
        </div>
        {/* First Module */}
        <div className="row mt-5 mb-3">
          <div className="poppins-semibold text-center">
            House Shifting Services with Best Packers and Movers in Bangalore
          </div>

          <div className="col-md-4 mt-5">
            <div
              className=""
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src={vicon} alt="loading..." />
            </div>
            <div className="poppins-black text-center pt-2">
              On-time shifting
            </div>
            <div className="poppins-regular text-center pt-1">
              Experience reliable packers and movers services
            </div>
          </div>

          <div className="col-md-4 mt-5">
            <div
              className=""
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src={vicon1} alt="loading..." />
            </div>
            <div className="poppins-black text-center pt-2">
              Economical prices
            </div>
            <div className="poppins-regular text-center pt-1">
              Hassle-free packers and movers services that fit your budget
            </div>
          </div>

          <div className="col-md-4 mt-5">
            <div
              className=""
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src={vicon2} alt="loading..." />
            </div>
            <div className="poppins-black text-center pt-2">
              Damage-proof packaging
            </div>
            <div className="poppins-regular text-center pt-1">
              Multi-layered packing to ensure safe movement of household goods
            </div>
          </div>
        </div>

        <img
          src={phomebanner}
          style={{ width: "100%", height: "auto" }}
          alt="loading"
          className="mt-3 mb-2"
        />

        <div className="row mt-5 mb-3">
          <div className="poppins-semibold text-center">
            What Sets Us Apart:{" "}
            <span className="poppins-black" style={{ color: "darkred" }}>
              Our Strengths
            </span>
          </div>

          <div className="col-md-4 mt-4">
            <div
              className="poppins-extralight"
              style={{ color: "darkred", textAlign: "center" }}
            >
              15+
            </div>
            <div className="poppins-regular text-center">Years of Trust</div>
          </div>

          <div className="col-md-4 mt-4">
            <div
              className="poppins-extralight"
              style={{ color: "darkred", textAlign: "center" }}
            >
              2000+
            </div>
            <div className="poppins-regular text-center">Moves Monthly</div>
          </div>

          <div className="col-md-4 mt-4">
            <div
              className="poppins-extralight"
              style={{ color: "darkred", textAlign: "center" }}
            >
              150+
            </div>
            <div className="poppins-regular text-center">Own Vehicles</div>
          </div>

          <div className="col-md-4 mt-4">
            <div
              className="poppins-extralight"
              style={{ color: "darkred", textAlign: "center" }}
            >
              300+
            </div>
            <div className="poppins-regular text-center">In-House Team</div>
          </div>

          <div className="col-md-4 mt-4">
            <div
              className="poppins-extralight"
              style={{ color: "darkred", textAlign: "center" }}
            >
              21+
            </div>
            <div className="poppins-regular text-center">Cities In India</div>
          </div>

          <div className="col-md-4 mt-4">
            <div
              className="poppins-extralight"
              style={{ color: "darkred", textAlign: "center" }}
            >
              40M
            </div>
            <div className="poppins-regular text-center">Trusted Customer</div>
          </div>
        </div>

        <div className="row mt-5 mb-3" style={{ justifyContent: "center" }}>
          <div className="col-md-8">
            <div className="poppins-semibold text-center">Why Choose Us?</div>

            <ul className="mt-3">
              <li className="poppins-regular">
                <span className="poppins-black">Lowest Price Guarantee: </span>
                We offer unbeatable prices. If you find a lower quote, we'll
                match it.
              </li>
              <li className="poppins-regular pt-2">
                <span className="poppins-black"> Best Quality Service: </span>
                Your belongings are in safe hands with our reliable packaging
                and moving services.
              </li>

              <li className="poppins-regular pt-2">
                <span className="poppins-black"> Flexibility: </span>
                Need to reschedule? No problem. Change your moving date to suit
                your needs.
              </li>

              <li className="poppins-regular pt-2">
                <span className="poppins-black"> Excellent Support: </span>
                Our dedicated team is always ready to assist you with any
                questions.
              </li>
              <li className="poppins-regular pt-2">
                <span className="poppins-black">Professional Expertise: </span>
                Our skilled movers ensure your items are packed and transported
                with care
              </li>
            </ul>
          </div>
        </div>

        {/* First Module Completed */}
        {/* Second Module */}
        <div
          className="row mt-4 mb-3 "
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <div className="poppins-semibold text-center">
            Need an Extra Hand?
          </div>
          <div className="poppins-regular text-center">
            Need more than just packing and moving? We've got you covered.
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-4 mt-3" onClick={handleShow}>
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <img
                src={vnicon}
                alt="loading..."
                style={{ width: "50px", height: "50px" }}
              />
            </div>

            <div className="poppins-black text-center pt-2">
              Appliance Installation
            </div>
            <div className="poppins-regular text-center  pt-1">
              We'll ensure your appliances are properly installed and ready to
              use.
            </div>
          </div>
          <div className="col-md-4 mt-3" onClick={handleShow}>
            <div
              onClick={handleShow}
              className="d-flex"
              style={{ justifyContent: "center" }}
            >
              <img
                src={vnicon2}
                alt="loading..."
                style={{ width: "50px", height: "50px" }}
              />
            </div>

            <div className="poppins-black text-center pt-2">
              Electrical Work
            </div>
            <div className="poppins-regular text-center  pt-1">
              Our licensed electricians can handle all your electrical needs.
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mb-3">
          <div className="col-md-2"></div>
          <div className="col-md-4 mt-3" onClick={handleShow}>
            <div
              onClick={handleShow}
              className="d-flex"
              style={{ justifyContent: "center" }}
            >
              <img
                src={vnicon2}
                alt="loading..."
                style={{ width: "50px", height: "50px" }}
              />
            </div>

            <div className="poppins-black text-center pt-2">
              Carpentry Services
            </div>
            <div className="poppins-regular text-center  pt-1">
              From furniture assembly to minor repairs, our carpenters are
              skilled professionals.
            </div>
          </div>
          <div className="col-md-4 mt-3" onClick={handleShow}>
            <div
              onClick={handleShow}
              className="d-flex"
              style={{ justifyContent: "center" }}
            >
              <img
                src={vnicon3}
                alt="loading..."
                style={{ width: "50px", height: "50px" }}
              />
            </div>

            <div className="poppins-black text-center pt-2">
              Painting Services
            </div>
            <div className="poppins-regular text-center  pt-1">
              Freshen up your new space with our professional painting services.
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mb-3" style={{ justifyContent: "center" }}>
          <div className="col-md-2"></div>
          <div className="col-md-4 mt-3" onClick={handleShow}>
            <div
              onClick={handleShow}
              className="d-flex"
              style={{ justifyContent: "center" }}
            >
              <img
                src={vnicon2}
                alt="loading..."
                style={{ width: "50px", height: "50px" }}
              />
            </div>

            <div className="poppins-black text-center pt-2">
              Plumbing Services
            </div>
            <div className="poppins-regular text-center  pt-1">
              We can handle any plumbing issues, from leaks to installations.
            </div>
          </div>
          <div className="col-md-4 mt-3" onClick={handleShow}>
            <div
              onClick={handleShow}
              className="d-flex"
              style={{ justifyContent: "center" }}
            >
              <img
                src={vnicon2}
                alt="loading..."
                style={{ width: "50px", height: "50px" }}
              />
            </div>

            <div className="poppins-black text-center pt-2">
              Rope Pulling Services
            </div>
            <div className="poppins-regular text-center  pt-1">
              Need to move heavy items through tight spaces? Our rope pulling
              experts can help.
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>

        {/*Second Module Completed  */}
        {/* Third Module Start */}
        <div className="row mt-5 mb-3">
          <div className="poppins-semibold text-center">
            SOME WORDS OF OUR HAPPY CUSTOMER
          </div>

          <div className="col-md-6 mt-5">
            <div className="poppins-regular" style={{ color: "black" }}>
              <span className="poppins-black">Meena Sharma - </span>
              Moving from Delhi to Chennai
            </div>

            <div className="poppins-regular-italic pt-3">
              "I was so grateful to find Vijay Home Services when I needed to
              move from Delhi to Chennai. They were very accommodating and
              flexible with my schedule. The movers were polite and helpful, and
              they packed everything securely. My belongings arrived safely and
              on time. I was so happy with their service."
            </div>
          </div>

          <div className="col-md-6 mt-5">
            <div className="poppins-regular" style={{ color: "black" }}>
              <span className="poppins-black">Rakesh Patel - </span>
              Rakesh Patel - Moving from Bengaluru to Mumbai
            </div>

            <div className="poppins-regular-italic pt-3">
              "I was extremely nervous about moving from Bengaluru to Mumbai,
              but Vijay Home Services made the entire process stress-free. Their
              team was professional, efficient, and very careful with my
              belongings. I was impressed by their packaging techniques and the
              speed of the move. I would highly recommend them to anyone looking
              for reliable packers and movers."
            </div>
          </div>

          <div className="col-md-6 mt-5">
            <div className="poppins-regular" style={{ color: "black" }}>
              <span className="poppins-black">Rakesh Patel - </span>
              Arijit Roy - Moving from Kolkata to Hyderabad
            </div>

            <div className="poppins-regular-italic pt-3">
              "I had a very positive experience with Vijay Home Services. They
              provided a detailed quote upfront and there were no hidden
              charges. The movers were experienced and knew how to handle my
              fragile items with care. I was impressed by their customer service
              and would definitely use them again."
            </div>
          </div>

          <div className="col-md-6 mt-5">
            <div className="poppins-regular" style={{ color: "black" }}>
              <span className="poppins-black">Rakesh Patel - </span>
              Kavita Joshi - Moving from Pune to Jaipur
            </div>

            <div className="poppins-regular-italic pt-3">
              "I was initially hesitant about hiring packers and movers, but I'm
              so glad I chose Vijay Home Services. They took care of everything
              from packing to unpacking, and the entire process was smooth and
              efficient. The movers were very friendly and professional. I would
              definitely recommend them to anyone moving from Pune to Jaipur."
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="poppins-extrabold">
            House Shifting Services Near You
          </div>
          <div className="poppins-black mt-3 mb-2">
            Serviceable areas in Bangalore
          </div>

          <div>
            <ul className="row pt-2" style={{ gap: "30px", display: "ruby" }}>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Whitefield
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Indira Nagar
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in HSR Layout
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in JP Nagar
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Electronic City
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in BTM Layout
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Rajajinagar
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Yelahanka
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Nagarbhavi
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Bommanahalli
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Marathahalli
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in KR Puram
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Sarjapur Road
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Banashankari
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Kadugodi
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Mahadevapura
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Shivajinagar
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers In Bellandur
              </li>
            </ul>
          </div>

          <div className="poppins-black mt-3 mb-2">Now in more cities</div>
          <div>
            <ul className="row pt-2" style={{ gap: "30px", display: "ruby" }}>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Ahmedabad
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Bhubaneshwar
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Delhi
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Ghaziabad
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Hyderabad
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Kolkata
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Mumbai
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in NCR
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Patna
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Surat
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Vizag
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Mover in Bangalore
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Chennai
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Faridabad
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Indore
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Mysore
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Kochi
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Gurugram
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Lucknow
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Noida
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Pune
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers in Vadodara
              </li>
            </ul>
          </div>

          <div className="poppins-black mt-3 mb-2">
            {" "}
            VHS Most Booking Routes
          </div>
          <div>
            <ul className="row pt-2" style={{ gap: "30px", display: "ruby" }}>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers From Bangalore to Chennai
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers From Bangalore to Delhi
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers & Movers From Bangalore to Hyderabad
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers From Bangalore to Kolkata
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers From Bangalore to Mumbai
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers From Bangalore to Pune
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers Bangalore to Ahmedabad
              </li>
              <li className="poppins-regular" style={{ textAlign: "justify" }}>
                Packers and Movers From Bangalore to Kochi
              </li>
            </ul>
          </div>
        </div>

        {/* <LoadScript
          googleMapsApiKey="AIzaSyBF48uqsKVyp9P2NlDX-heBJksvvT_8Cqk"
          libraries={["places"]}
        >
          <GoogleMap
            ref={mapRef}
            center={{ lat: 12.9716, lng: 77.5946 }}
            zoom={10}
            mapContainerStyle={{
              height: "400px",
              width: "100%",
              position: "relative",
            }}
          >
            <Autocomplete
              onLoad={(autocomplete) => {
                autocompleteRef.current = autocomplete;
              }}
              options={{
                fields: ["formatted_address", "geometry", "name"],
                types: ["geocode"],
              }}
            >
              <input
                type="text"
                placeholder="Search for a location"
                className="map_input"
                style={{
                  boxSizing: "border-box",
                  border: "1px solid transparent",
                  width: "240px",
                  height: "32px",
                  padding: "0 12px",
                  borderRadius: "3px",
                  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                  fontSize: "14px",
                  outline: "none",
                  textOverflow: "ellipsis",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 2,
                  backgroundColor: "orange",
                }}
              />
            </Autocomplete>
          </GoogleMap>
        </LoadScript> */}

        {/* <LoadScript
          googleMapsApiKey="AIzaSyBF48uqsKVyp9P2NlDX-heBJksvvT_8Cqk"
          libraries={["places"]} 
        >
          <Autocomplete
            onLoad={(autocomplete) => {
              autocompleteRef.current = autocomplete;
            }}
            options={{
              fields: ["formatted_address", "geometry", "name"],
              types: ["geocode"],
            }}
            // onPlaceChanged={handlePlaceSelect} // You can handle place changes here
          >
            <input
              type="text"
              placeholder="Search for a location"
              className="map_input"
              style={{
                boxSizing: "border-box",
                border: "1px solid transparent",
                width: "240px",
                height: "32px",
                padding: "0 12px",
                borderRadius: "3px",
                boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                fontSize: "14px",
                outline: "none",
                textOverflow: "ellipsis",
                position: "absolute",
                top: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 2,
                backgroundColor: "orange",
              }}
            />
          </Autocomplete>
        </LoadScript> */}

        <div className="row mt-5">
          <div className="poppins-extrabold">
            {" "}
            Best Packers and Movers in Bangalore
          </div>

          <p className="poppins-regular pt-2">
            Moving can be a stressful experience, but with Vijay Home Services,
            you have a trusted team to make the process smooth and hassle-free.
            As one of the leading packers and movers in Bangalore, we pride
            ourselves on offering efficient, reliable, and personalized services
            tailored to meet your unique needs. Our experienced team uses
            advanced methods and high-quality materials to ensure your
            belongings are packed, transported, and delivered safely.
          </p>

          <p className="poppins-regular">
            With a presence in over 21 cities and a fleet of more than 150
            vehicles, we have successfully helped countless individuals and
            businesses relocate. We’ve earned their trust through our
            professionalism and commitment to quality. Whether you’re moving
            locally or long-distance, our expertise ensures your move is
            seamless, with every detail handled meticulously.
          </p>

          <p className="poppins-regular ">
            At Vijay Home Services, we go beyond just moving goods—we provide
            peace of mind. Our innovative tracking systems, expert handling, and
            competitive pricing set us apart from the rest. Plus, our Lowest
            Price Guarantee ensures you receive top-notch service without
            overspending.
          </p>
          <p className="poppins-regular ">
            Our clients return to us not only for our reliability but also for
            our dedication to delivering their belongings on time, every time.
            Trust Vijay Home Services to make your relocation stress-free. Once
            you experience our exceptional service, your search for the best
            packers and movers will end with us!
          </p>
        </div>

        <div className="row mt-2">
          <div className="poppins-extrabold">
            Vijay Home Services Packing and Moving Solutions in Bangalore
          </div>
          <div className="poppins-regular pt-2">
            At Vijay Home Services, we offer a wide range of packing and moving
            services to make your relocation—within Bangalore, across India, or
            even internationally—smooth and hassle-free. Here’s how we can
            assist you:
          </div>
        </div>

        <div className="row mt-2">
          <div className="poppins-extrabold">
            Domestic Packers and Movers in Bangalore
          </div>
          <div className="poppins-regular pt-2">
            “We provide reliable house shifting services in Bangalore and across
            India. Our team customizes each relocation plan to suit your
            needs—considering your time, budget, and the volume of belongings.
            Whether it’s a local or long-distance move, we ensure a smooth
            transition.
          </div>
        </div>

        <div className="row mt-2">
          <div className="poppins-extrabold">
            Office Shifting Packers and Movers in Bangalore
          </div>
          <div className="poppins-regular pt-2">
            Our commercial relocation services cater to businesses moving
            offices within or outside Bangalore. We prioritize the safety of
            your office equipment and documents, ensuring timely delivery to
            your new location.
          </div>
        </div>

        <div className="row mt-2">
          <div className="poppins-extrabold">
            Intercity Packers and Movers in Bangalore
          </div>
          <div className="poppins-regular pt-2">
            Specializing in intercity relocation, we offer a robust network of
            movers across major cities in India. Whether you're relocating from
            Bangalore to another city, we ensure that your belongings arrive
            safely and on time.
          </div>
        </div>

        <div className="row mt-2">
          <div className="poppins-extrabold">
            Interstate Home Shifting Services
          </div>
          <div className="poppins-regular pt-2">
            As one of the top packers and movers, we provide interstate
            relocation services tailored to your needs. We consider your
            resources, budget, and the size of your belongings to ensure a
            worry-free moving experience.
          </div>
        </div>

        <div className="row mt-2">
          <div className="poppins-extrabold">
            Secure Warehouse/Storage Services
          </div>
          <div className="poppins-regular pt-2">
            Need temporary storage? Our secure warehouse facilities are ideal
            for short- or long-term storage. Your belongings will be safely
            stored until you’re ready to move them to your new location.
          </div>
        </div>

        <div className="row mt-2">
          <div className="poppins-extrabold">Car Transportation Services</div>
          <div className="poppins-regular pt-2">
            Your car is more than just a vehicle; it's a valuable asset. We
            offer professional car transportation services to ensure your car
            reaches its destination securely and without delay.
          </div>
        </div>

        <div className="row mt-2">
          <div className="poppins-extrabold"> Professional Bike Relocation</div>
          <div className="poppins-regular pt-2">
            Weunderstand how important your bike is to you. That’s why we use
            high-quality packing materials and equipment to ensure your bike is
            safely handled and delivered, whether you’re moving locally or to
            another city.
          </div>
        </div>

        <div className="row mt-2">
          <div className="poppins-extrabold">
            {" "}
            Commercial Shifting Solutions
          </div>
          <div className="poppins-regular pt-2">
            Our secure warehouses are equipped with temperature control and 24/7
            surveillance, ideal for businesses needing to store office supplies,
            furniture, or sensitive equipment.
          </div>

          <div className="poppins-regular pt-2">
            At Vijay Home Services, the safety of your belongings is our
            priority. Whether it’s a local shift, interstate move, or
            international relocation, we’re here to assist you every step of the
            way!
          </div>
        </div>

        {/* <div className=" mt-3">
          <div className="poppins-extrabold">
            Porter’s Packers and Movers Prices
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-md-5 mt-3">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th className="poppins-black">Type</th>
                    <th className="poppins-black">Price (₹)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="poppins-regular">
                      Nano-Shifting(Micro-shifting)
                    </td>
                    <td className="poppins-regular">1300</td>
                  </tr>
                  <tr>
                    <td className="poppins-regular">1 RK</td>
                    <td className="poppins-regular">1500</td>
                  </tr>
                  <tr>
                    <td className="poppins-regular">1 BHK</td>
                    <td className="poppins-regular"> 2200</td>
                  </tr>
                  <tr>
                    <td className="poppins-regular">2 BHK</td>
                    <td className="poppins-regular"> 4600</td>
                  </tr>
                  <tr>
                    <td className="poppins-regular">3 BHK</td>
                    <td className="poppins-regular"> 6300</td>
                  </tr>
                  <tr>
                    <td className="poppins-regular">4 BHK</td>
                    <td className="poppins-regular"> 10600</td>
                  </tr>
                </tbody>
              </table>
              <p className="poppins-regular" style={{ textAlign: "left" }}>
                *Please note, that prices may vary based on the quantity of
                goods moved.
              </p>
            </div>
          </div>
        </div> */}

        <div className="row mt-2">
          <div className="poppins-extrabold">
            How Vijay Home Services Packers and Movers Make Moving Easier for
            You
          </div>

          <ol className="mx-3">
            <li className="poppins-regular mt-2">
              Express Your Requirements Simply share your moving requirements
              with us via our online platform, and our skilled team will handle
              the rest.
            </li>
            <li className="poppins-regular mt-2">
              Get a Free Quote After you select the services you need, we
              provide a transparent quote to ensure you get the best rates.
            </li>
            <li className="poppins-regular mt-2">
              Select the Date and Time Choose your preferred moving date and
              time through our online dashboard. Weoffer flexible scheduling to
              fit your needs.
            </li>
            <li className="poppins-regular mt-2">
              Team at Your Doorstep Our professional movers arrive on time,
              fully equipped to manage packing, loading, and unpacking with
              efficiency and care.
            </li>
          </ol>
        </div>

        <div className="row mt-2">
          <div className="poppins-extrabold">
            Summary of Packers and Movers Quotation in Bangalore
          </div>
          <div className="poppins-regular pt-2">
            The cost of moving varies based on the number of items, distance,
            and service requirements. Relocating within Bangalore is generally
            less expensive than intercity moves, but large moves may cost more
            depending on the volume of items. However our Services Starts from
            1200 onwards.
          </div>
        </div>

        <div className="row mt-4">
          <div className="poppins-extrabold">
            Benefits of Using Vijay Home Services Packers and Movers in
            Bangalore
          </div>

          <div className="poppins-black pt-3">Door-to-Door Service</div>
          <div className="poppins-regular">
            Wehandle everything from packing to delivery, ensuring a seamless
            transition to your new home.
          </div>

          <div className="poppins-black pt-3"> Impeccable Packing Services</div>
          <div className="poppins-regular">
            Weuse high-quality packing materials to secure your belongings and
            minimize the risk of damage.
          </div>

          <div className="poppins-black pt-3">
            {" "}
            Transport, Loading, and Unloading Services
          </div>
          <div className="poppins-regular">
            Our movers handle all heavy lifting, ensuring your items are
            transported with care.
          </div>

          <div className="poppins-black pt-3"> Time and Effort-Saving</div>
          <div className="poppins-regular">
            Wemanage the entire process, allowing you to focus on other
            important aspects of your move.
          </div>

          <div className="poppins-black pt-3">
            {" "}
            Insurance for Your Belongings
          </div>
          <div className="poppins-regular">
            Weoffer transit insurance to protect your valuables during the move,
            giving you peace of mind.
          </div>
        </div>

        <div className="row mt-5" style={{ justifyContent: "center" }}>
          <div className="poppins-extrabold text-center">
            Frequently Asked Questions
          </div>
          {/* <div className="poppins-regular pt-2 text-center">
            Quick answers to common queries when booking Packers and Movers in
            Mumbai
          </div> */}
          <div className="col-md-10 mt-4 mb-4">
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="poppins-regular">
                  What services do you offer as part of your Packers and Movers?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  Weoffer end-to-end services including packing, loading,
                  transporting, unloading, and unpacking of household and office
                  goods.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="poppins-regular">
                  How do you ensure the safety of fragile and valuable items?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  Weuse high-quality packing materials such as bubble wrap,
                  cartons, and foam to secure fragile items. Our team is trained
                  to handle delicate items with care.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header className="poppins-regular">
                  Do you provide door-to-door relocation services?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  Yes, we provide complete door-to-door services to ensure a
                  hassle-free relocation experience.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header className="poppins-regular">
                  Are there any hidden charges in your pricing?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  No, our pricing is transparent, and we provide detailed quotes
                  upfront. There are no hidden fees.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header className="poppins-regular">
                  What areas do you serve?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  Weprovide packers and movers services across 21+ cities in
                  India, covering most major metropolitan areas.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header className="poppins-regular">
                  Can I reschedule my moving date?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  Yes, we offer flexible scheduling. You can reschedule your
                  move by contacting our support team in advance.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header className="poppins-regular">
                  What if my goods get damaged during transit?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  Wetake all precautions to prevent damage, but in the rare case
                  of damage, we offer insurance coverage to compensate for any
                  losses.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header className="poppins-regular">
                  How do you determine the cost of the move?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  The cost is determined by factors such as the volume of goods,
                  distance, type of services required (packing/unpacking), and
                  any additional requests like insurance or special handling.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header className="poppins-regular">
                  Do you offer insurance for the move?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  Yes, we provide optional insurance coverage to protect your
                  belongings during transit.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header className="poppins-regular">
                  How do I book your services?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  You can book our services by contacting us via phone, email,
                  or through our website. A booking confirmation will be
                  provided once details are finalised.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header className="poppins-regular">
                  How do I book your services?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  You can book our services by contacting us via phone, email,
                  or through our website. A booking confirmation will be
                  provided once details are finalised.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header className="poppins-regular">
                  Do you provide transportation for vehicles like cars or bikes?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  Yes, we offer specialised vehicle transportation services for
                  cars and bikes.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header className="poppins-regular">
                  How early should I book my move?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  Werecommend booking at least a week in advance to ensure
                  availability, especially during peak moving seasons.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header className="poppins-regular">
                  How long does the moving process take?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  The time depends on factors like the distance of the move and
                  the volume of goods. For local moves, it typically takes a
                  day, while long-distance moves may take a few days.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="14">
                <Accordion.Header className="poppins-regular">
                  Can you handle international relocations?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  At the moment, we specialize in domestic relocations within
                  India.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="15">
                <Accordion.Header className="poppins-regular">
                  What items should I avoid packing during the move?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  Avoid packing hazardous materials like flammable liquids,
                  explosives, and perishables like food items, as they may not
                  be safe for transportation.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="16">
                <Accordion.Header className="poppins-regular">
                  Can I track my shipment during the move?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  Yes, we offer tracking services so that you can monitor the
                  status of your shipment during transit.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="17">
                <Accordion.Header className="poppins-regular">
                  Do you offer storage services if I need to temporarily store
                  my goods?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  Yes, we provide secure and reliable short-term and long-term
                  storage facilities if needed.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="18">
                <Accordion.Header className="poppins-regular">
                  What kind of vehicles do you use for transporting goods?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  Wehave a fleet of vehicles ranging from small trucks for local
                  moves to large trucks for long-distance relocations, ensuring
                  the right vehicle for your needs.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="19">
                <Accordion.Header className="poppins-regular">
                  Do I need to be present during the packing and moving process?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  It is preferable for you or a representative to be present to
                  supervise and provide guidance, but we can manage the process
                  independently if needed.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="20">
                <Accordion.Header className="poppins-regular">
                  How do I prepare for my move?
                </Accordion.Header>
                <Accordion.Body className="poppins-light">
                  Werecommend decluttering, labelling boxes, and informing us of
                  any special requests ahead of time to ensure a smooth and
                  efficient move.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>

        <div className="poppins-extrabold">Conclusion</div>
        <div className="poppins-regular pt-3">
          At Vijay Home Services, customer satisfaction is our top priority.
          From the moment you book our services to the successful completion of
          your move, we ensure a smooth, secure relocation. Join the many
          satisfied customers who trust us with their moving needs and
          experience a hassle-free move with the best packers and movers in
          Bangalore.
        </div>
        <div className="poppins-regular pt-2 pb-3">
          We’re here to make your moving experience easy, efficient, and
          worry-free. Choose Vijay Home Services for all your moving solutions!
        </div>

        {/* Third Completed */}
      </div>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="poppins-semibold">Enquiry Add</div>

          <div className="mt-2">
            <div className="poppins-light">Name</div>
            <input
              type="text"
              className="input col-md-12 mt-2 vhs-input-value"
              value={customer}
              onChange={(e) => setcustomer(e.target.value)}
            />
          </div>

          <div className="">
            <div className="poppins-light">Contact Number</div>
            <input
              type="text"
              value={mobilenumber}
              className="input col-md-12 mt-2 vhs-input-value"
              onChange={(e) => setMobilenumber(e.target.value)}
            />
          </div>

          <div className="">
            <div className="poppins-light">Category</div>
            <select
              value={selectcategory}
              onChange={(e) => setselectcategory(e.target.value)}
              className="input col-md-12 mt-2 vhs-input-value"
            >
              <option>--select category--</option>
              <option>Appliance Installation</option>
              <option>Electrical Work</option>
              <option>Carpentry Services</option>
              <option>Painting Services</option>
              <option>Plumbing Services</option>
              <option>Rope Pulling Services</option>
            </select>
          </div>

          <div
            onClick={addEnquiry}
            className="poppins-black"
            style={{
              backgroundColor: "darkred",
              padding: "7px",
              borderRadius: "5px",
              marginTop: "20px",
              color: "white",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Submit
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={openResetModal}
        style={{ width: "100%" }}
        centered
        onHide={handleResetModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="modal_grid">
          <div className="modal_header">
            <img src={cityimg} alt="city images" style={{ width: "100%" }} />
          </div>

          <div className="modal_body">
            <div
              className="px-3 pt-3 title poppins-semibold"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <span>
                <img
                  src={indiaflg}
                  alt="loading..."
                  style={{
                    width: "23px",
                    height: "23px",
                    marginRight: "10px",
                    borderRadius: "50px",
                  }}
                />
              </span>
              India
            </div>
            <div className="city-grid p-3">
              {city.map((city) => (
                <div className="city-item" key={city._id}>
                  <div
                    className={`city-name ${
                      activeCity === city.city ? "active" : ""
                    }`}
                    onClick={() => handleChange(city)}
                  >
                    <i
                      className={`fa-solid fa-location-dot ${
                        activeCity === city.city ? "active-icon" : ""
                      }`}
                      style={{
                        color: "darkred",
                        marginTop: "3px",
                        fontSize: "15px",
                      }}
                    ></i>
                    <p className="poppins-regular">{city.city}</p>
                  </div>
                </div>
              ))}
            </div>

            <div
              className="row mt-4 px-3 pb-3"
              style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
            >
              <div className="col-md-5">
                <div className=" poppins-semibold mt-1">
                  <span>
                    <img
                      src={dubai}
                      alt="loading..."
                      style={{
                        width: "23px",
                        height: "23px",
                        marginRight: "10px",
                        borderRadius: "50px",
                      }}
                    />
                  </span>
                  Dubai{" "}
                  <span
                    className="poppins-light"
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Coming Soon
                  </span>
                </div>
              </div>
              <div className="col-md-5">
                <div className=" poppins-semibold mt-1">
                  <span>
                    <img
                      src={london}
                      alt="loading..."
                      style={{
                        width: "23px",
                        height: "23px",
                        marginRight: "10px",
                        borderRadius: "50px",
                      }}
                    />
                  </span>
                  London{" "}
                  <span
                    className="poppins-light"
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Coming Soon
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Footer />
    </div>
  );
}
export default Home;
